<template>
  <div class="flex">
    <el-carousel v-if="!isMobile" ref="carousel" class="left-part" :direction="isMobile ? '' : 'vertical'" indicator-position="none" @change="change">
      <el-carousel-item v-for="(s, index) in videos" :key="index">
        <VideoImage style="height: 100%;width: 100%;" @click="router.push('/video/' + s.videoId)" :src="s.imgUrl" />
      </el-carousel-item>
    </el-carousel>
    <div v-else class="left-part swiper mySwiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(s, index) in videos" :key="index">
          <VideoImage isMobile style="height: 100%;width: 100%;" @click="router.push('/video/' + s.videoId)" :src="s.imgUrl" />
          <p class="title-tip">
            <span class="title-v">{{s.title}}</span>
            <span>{{index + 1}}/{{videos.length}}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="right-part" :style="{backgroundColor: videos[current]?.bgColor}">
      <div @mouseenter.stop="handleMouseEnter" @mouseleave.stop="handleMouseLeave" class="r-inner">
        <div class="list-wrapper">
          <p v-for="(s, index) in videos" class="list" @mouseenter="enter(index)" @click="router.push('/video/' + s.videoId)" :class="{'active': index === current}" :key="s.id">{{s.title}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { getOrder } from "@api";

import VideoImage from '@c/VideoImage.vue'
import { ref } from '@vue/reactivity';
import { useRouter } from 'vue-router';
import { onMounted } from '@vue/runtime-core';
export default {
  name: "VideoRecTopBanner",
  components: {
    VideoImage
  },
  props: {
    videos: Array
  },
  setup () {
    const current = ref(0)
    const carousel = ref(null)
    const router = useRouter()

    onMounted(() => {
      new window.Swiper(".mySwiper", {
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false,
        // },
        loop: true
      });
    })

    return {
      change (v) {
        current.value = v
      },
      enter (v) {
        carousel.value.handleIndicatorClick(v)
      },
      handleMouseEnter () {
        carousel.value?.handleMouseEnter()
      },
      handleMouseLeave () {
        carousel.value?.handleMouseLeave()
      },
      current,
      router,
      isMobile: window.innerWidth < 767,
      carousel
    }
  }
};
</script>


<style scoped lang="stylus">
.flex {
  display: flex;
  height: 360px;
}
.left-part {
  width: 62%;
}
.left-part ::v-deep(.el-carousel__container) {
  height: 360px;
}
.right-part {
  flex: 1;
  background: rgb(124, 149, 152);
  transition: background .4s;
}
.r-inner {
  padding: 24px;
  float: right;
  width: 360px;
  box-sizing: border-box;
  height: 100%;
}
.list-wrapper {
  overflow: auto;
  height: 100%;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.6);
  padding: 24px 0;
  box-sizing: border-box;
}
.list {
  position: relative;
  width: 210px;
  height: 20px;
  padding: 0 0 0 32px;
  margin: 0 0 16px 0;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  word-break: break-all;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  color: rgb(184, 184, 184);
  cursor: pointer;
}
.list.active {
  color: #fff;
}
.list.active::before {
  content: '';
  position: absolute;
  left: 32px;
  top: 1px;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-width: 6px 7px;
  border-right-color: rgb(255, 255, 255);
  transform: translate(-150%, 25%);
}

@media screen and (max-width: 767px) {
  .right-part {
    display: none;
  }
  .left-part {
    width: 100%;
  }
  .left-part ::v-deep(.el-carousel__container) {
    height: calc((100vw - 20px) * 0.5625)
  }
  .flex {
    height: calc((100vw - 20px) * 0.5625 + 60px)
  }
  .swiper-slide {
    position: relative;
  }
  .swiper ::v-deep(.mobile-pic) {
    height: calc(100% - 60px)
  }
  .title-tip {
    position: absolute;
    bottom: 0;
    height: 60px;
    width: calc(100vw - 40px);
    line-height: 60px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    background: rgb(236,236,236);
  }
  .title-v {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 0;
    margin-right: 10px;
  }
}

</style>
