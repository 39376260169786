<template>
  <div class="outer-wrapper" :class="position">
    <div class="left-bar" @click="togglePosition">
      <span class="el-icon-arrow-left"></span>
    </div>
    <div class="flex-right-hand">
      <div ref="scrollArea" class="center">
        <div class="swiper">
          <el-row :gutter="24">
            <el-col :xs="{span: '12'}" :sm="{span: '4-8'}" v-for="(s, index) in videos.slice(0, 5)" :key="index" >
              <NewVideoItem class="new-rec" :width="300" :video="s" />
            </el-col>
          </el-row>
        </div>
        <div class="swiper">
          <el-row :gutter="24">
            <el-col :xs="{span: '12'}" :sm="{span: '4-8'}" v-for="(s, index) in videos.slice(5, 10)" :key="index" >
              <NewVideoItem class="new-rec" :width="300" :video="s" />
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="right-hander" @click="goRight" v-if="isMobile">
          <i class="el-icon-arrow-right"></i>
        </div>
    </div>
    
    <div class="right-bar" @click="togglePosition">
      <span class="el-icon-arrow-right"></span>
    </div>
  </div>
</template>

<script>
// import { getOrder } from "@api";
import NewVideoItem from '@c/NewVideoItem.vue'
import { ref } from '@vue/reactivity';
export default {
  name: "SubNewGood",
  components: {
    NewVideoItem
  },
  props: {
    videos: Array
  },
  setup () {
    const scrollArea = ref(null)
    const position = ref('right')
    return {
      position,
      togglePosition () {
        if (position.value === 'right') {
          position.value = 'left'
        } else {
          position.value = 'right'
        }
      },
      isMobile: window.innerWidth < 768,
      scrollArea,
      goRight() {
        scrollArea.value.scrollLeft = scrollArea.value.scrollLeft + scrollArea.value.offsetWidth
      }
    }
  }
};
</script>


<style scoped lang="stylus">
.outer-wrapper,.swiper {
  display: flex;
}
.left-bar,.right-bar {
  width: 40px;
  display: flex;
  align-items: center;
  font-size: 40px;
  color: #ccc;
  cursor: pointer;
}
.left-bar:hover,.right-bar:hover {
  color: #888;
}
.right .left-bar, .left .right-bar {
  display: none;
}
.center {
  width: calc(100% - 40px);
  display: flex;
  overflow: hidden;
  scroll-behavior: smooth;

}
.swiper {
  /* justify-content: space-between; */
  width: 100%;
  flex-shrink: 0;
  transition: transform .28s;
  text-align: left;
}
/* .new-rec {
  width: 180px;
  margin-right: 20px;
  flex: auto;
  flex-shrink: 0;
} */
.left .swiper {
  transform: translateX(-100%);
}
.el-row {
  width: 100%;
}
@media screen and (max-width: 767px) {
  .left-bar, .right-bar {
    display: none;
  }
  .center {
    width: calc(100vw - 52px);
    overflow: auto;
  }
  
  .el-col-xs-12.el-col-sm-4-8 {
    display: block;
    width: 210px;
    max-width: 210px;
    flex: auto;
    flex-shrink: 0;
  }
  .el-row {
    flex-wrap: nowrap;
  }
  .swiper {
    width: auto;
    padding-bottom: 10px;
  }
  .flex-right-hand {
    display flex
    justify-content: space-between;
    & .right-hander {
      width 30px
      background: rgba(0,0,0,.2);
      display flex
      align-items center
      justify-content center
      font-size 20px
      color #fff
      font-weight bold
      margin-left: 2px;
    }
  }
}
</style>
