<template>
  <div>
    <div class="container">
      <VideoRecTopBanner class="top-swapper" v-if="banners.length" :videos="banners" />
      <p class="title" v-if="goods.length">最新精品</p>
      <SubNewGood v-if="goods.length" :videos="goods"/>
      <p class="title">
        全部课程
      </p>
      <p class="class-list"><span class="t-title" :class="{active: tobj.id === t.id}" @click="tobj = t" :key="t.id" v-for="t in thirdMenus">{{t.name}}</span></p>
      <div v-loading.fullscreen.lock="loading" class="container-wrapper">
        <div class="p-c container">
          <el-row :gutter="24" v-if="videos.length">
            <el-col class="v-item" v-for="v in videos" :key="v.id" :xs="{span: '12'}" :sm="{span: '4-8'}"  >
              <NewVideoItem :width="300" :video="v" />
            </el-col>
          </el-row>
          <!-- <div class="v-wrapper" v-if="videos.length">
            <div class="v-item-inner" >
              
            </div>
          </div> -->

          <span class="no-data" v-else>
            ...暂无数据
          </span>
        </div>
      </div>
      <p class="pagenation">
        <Pagenation @change="pageChange" :pageSize="20" :currentPage="cp"  :total="total" />
      </p>
    </div>
    <slot name="footer" />
  </div>
</template>

<script>
import { getVideoPage as _getVideoPage, categoryBanner, categoryRecNew } from "@api"
import NewVideoItem from '@c/NewVideoItem.vue'
import Pagenation from '@c/Pagenation.vue'
import VideoRecTopBanner from '@c/VideoRecTopBanner.vue'
import SubNewGood from '@c/SubNewGood.vue'
import { reactive, computed, watch, toRefs, onBeforeUnmount, ref } from "vue";
import { useRoute, useRouter } from 'vue-router'
export default {
  name: "medias",
  components: {
    NewVideoItem,
    Pagenation,
    VideoRecTopBanner,
    SubNewGood
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const timer = ref(0)
    const state = reactive({
      fid: Number(route.params.fid || 0),
      sid: Number(route.params.sid || 0),
      tobj: {},
      videos: [],
      goods: [],
      currentPage: 0,
      total: 0,
      loading: false,
      menus: window.__menus,
      banners: []
    })

    const currentClassId = computed(() => state.tobj.id ? state.tobj.id : state.sid ? state.sid : state.fid)

    const thirdMenus = computed(() => {
      try {
        return state.menus.find(i => i.id === state.fid).children.find(j => j.id === state.sid).children
      } catch (error) {
        return []
      }
    })

    const cp = computed(() => state.currentPage + 1)

    const getCategoryBanner = (id) => {
      state.banners = []
      categoryBanner(id).then(res => {
        state.banners = res.data.sort((a,b) => a.orderNum - b.orderNum)
      })
    }

    onBeforeUnmount(() =>{
      window.menuDone = null
    })

    const getVideoPage = (refresh) => {
      window.setClass && window.setClass(state.fid, state.sid)
      clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        state.loading = true
        refresh && categoryRecNew(currentClassId.value).then(res => state.goods = res.data)
        _getVideoPage({
          type: route.name === 'videos' ? 0 : 1,
          categoryId: currentClassId.value,
          currentPage: refresh ? state.currentPage = 0 : state.currentPage,
          pageSize: 20
        }).then(res => {
          state.videos = res.data.records
          state.total = res.data.total
        }).finally(() => {
          state.loading = false
        })
      }, 200)
      
      
    }
    const pageChange = (pageNo) => {
      state.currentPage = pageNo - 1
      getVideoPage()
    }

    currentClassId.value && getVideoPage(true)

    watch([currentClassId], () => {
      // eslint-disable-next-line no-debugger
        // debugger
      currentClassId.value && getVideoPage(true)
    })

    watch(() => state.fid, (v) => {
      v && getCategoryBanner(v)
    }, { immediate:true })

    window.menuDone = (_menus) => {
      const menus = _menus || window.__menus
      // eslint-disable-next-line no-debugger
        // debugger
      if (menus) {
        state.menus = menus
        if(!state.fid) {
          state.fid = menus[0].id
        }
        if (!state.sid) {
          try {
            state.sid = menus.find(i => i.id === state.fid)?.children?.[0]?.id
          // eslint-disable-next-line no-empty
          } catch (error) { 
            
          }
        }
      }
    }

    window.menuDone()

    watch(route, (v) => {
      // eslint-disable-next-line no-debugger
      if (
        ((history.state.forward && !history.state.forward.match(/^\/audio/)) && v.name === 'audios') 
        || 
        ((history.state.forward && !history.state.forward.match(/^\/video/)) && v.name === 'videos')
        ||
        (!history.state.forward && (v.name === 'videos' || v.name === 'audios'))
        ) {
        state.tobj = {}
        state.fid = Number(route.params.fid || 0)
        state.sid = Number(route.params.sid || 0)
      }
    })

    return {
      ...toRefs(state),
      currentClassId,
      getVideoPage,
      pageChange,
      thirdMenus,
      router,
      cp
    }
  }
};
</script>


<style scoped lang="stylus">

.top-swapper {
  margin-top 20px;
  overflow: hidden;
}

.container-wrapper {
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 50px;
  position: relative;
}


.pagenation {
  height: 50px;
  width: 100%;
  text-align: center;
}

.v-item {
  margin-bottom 30px
}

.p-c {
  min-height: 100%;
  position: relative;

  .no-data {
    top: 40%;
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;
    text-align: center;
    color: #999;
  }
}
.title {
  font-size: 22px;
  line-height: 40px;
  font-family: '微软雅黑';
  margin-top: 25px;
  margin-bottom: 15px;
}
.t-title {
  font-size 16px
  margin-right 20px
  margin-bottom 20px
  margin-top 10px
  display inline-block
  cursor pointer
  &.active, &:hover {
    color: #cc1c1a
  }
}
.class-list {
  line-height 15px;
}
@media screen and (max-width: 767px) {
  .el-col-xs-12.el-col-sm-4-8 {
    display: block;
    max-width: 50%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    flex: 0 0 50%;
  }
  .class-list {
    white-space nowrap;
    overflow auto
  }
} 

</style>
